import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import mask from "@alpinejs/mask";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
Alpine.plugin(mask);
Livewire.start();
window.Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
});
// document.addEventListener("turbo:load", () => {
//     $("[product-tab]").each(function (index, el) {
//         $(el).on("click", function (e) {
//             const activeClasse = "text-blue-600";
//             e.preventDefault();
//             $(el).addClass(activeClasse);
//             $(el).parent().siblings().children().removeClass(activeClasse);

//             $("[product-content]").each(function (index2, el2) {
//                 if ($(el).attr("product-tab") != $(el2).attr("product-content")) {
//                     $(el2).addClass("hidden");
//                 } else {
//                     $(el2).removeClass("hidden");
//                 }
//             });
//         });
//     });
// });
